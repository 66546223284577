import React from "react";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";
import classNames from "classnames";
import useNetworkStatus from "utils/useNetworkStatus";
import Observations from "../Observations";
import { PICKUP_STATUS } from "pages/Picking/usePicking";

const isFinalEvent = (event) => {
  const finalEvents = [
    "ITM_DELIVERY_PARTIALLY_RETURNED_TO_STORE",
    "ITM_DELIVERY_NOT_COMPLIANT",
    "ITM_DELIVERY_COMPLIANT",
  ];

  return finalEvents.includes(event);
};

const DeliveryItem = ({
  item,
  isReordering,
  isLastItem,
  updatePosition,
  setModalReturnModalIsOpen,
  isOrderReturned = false,
  halfDay,
  correspondingPickingId,
}) => {
  const isOnline = useNetworkStatus();
  const isValidated = Boolean(item.delivered);
  const isDelayed = Boolean(item.isDelayed);
  const someArticleHaveNotBeenDelivered = Boolean(
    item.someArticleHaveNotBeenDelivered
  );
  const displayButtonArticlesReturnedToStore =
    (someArticleHaveNotBeenDelivered || item.customerIsAbsent) &&
    !isFinalEvent(item.order.last_event) &&
    !isOrderReturned;
  const history = useHistory();

  const pickingButtonClasses = classNames("delivery-item__picking-btn", {
    "delivery-item__picking-btn--completed":
      item.order.picking_status === PICKUP_STATUS.PICKUP_OK.value,
    "delivery-item__picking-btn--partially":
      item.order.picking_status === PICKUP_STATUS.PICKUP_PARTIALLY.value,
    "delivery-item__picking-btn--failed":
      item.order.picking_status === PICKUP_STATUS.PICKUP_FAILED.value,
  });

  const hasInstallArticle = item.order.articles.some(
    ({ install_article }) => install_article
  );
  const hasDirectDropOff = item.order.articles.some(
    ({ direct_drop_article }) => direct_drop_article
  );

  const hasDropOff = item.order.articles.some(
    ({ drop_article }) => drop_article
  );

  const haveHeavyPackage = item.order.articles.some(
    ({ has_heavy_package }) => has_heavy_package
  );

  return (
    <div className="delivery-item">
      <Link
        to={`/delivery/${item.id}`}
        onClick={(e) => {
          if (isReordering) {
            e.preventDefault();
          }
        }}
      >
        <div
          className={`round-delivery-item ${isValidated ? "-validated" : ""} ${
            isDelayed ? "-delayed" : ""
          }`}
        >
          {isValidated && (
            <i
              className={`fa ${
                isDelayed ? "fa-sync" : "fa-check-circle"
              } fa-lg validationIcon`}
              aria-hidden="true"
            />
          )}

          <i
            className={`accessory fa fa-chevron-right ${
              isReordering ? "hidden" : ""
            }`}
            aria-hidden="true"
          />

          {isReordering && (
            <div>
              {Number(item.position) !== 1 && (
                <button
                  type="button"
                  className="reorder -up button button-outline"
                  onClick={updatePosition({
                    halfDay,
                    item,
                    direction: "UP",
                  })}
                >
                  <i className="fa fa-arrow-up" aria-hidden="true" />
                </button>
              )}
              {!isLastItem && (
                <button
                  type="button"
                  className="reorder -down button button-outline"
                  onClick={updatePosition({
                    halfDay,
                    item,
                    direction: "DOWN",
                  })}
                >
                  <i className="fa fa-arrow-down" aria-hidden="true" />
                </button>
              )}
            </div>
          )}

          {item.isSmsSent ? (
            <div className={`smsTag  ${isReordering ? "reorder-offset" : ""}`}>
              <i className="fa fa-check" />
              SMS
            </div>
          ) : null}

          <div className="row">
            <div className="column column-50">
              <div>
                <span className="label">Magasin</span> :{" "}
                {item.order.contractor.identity.code}
              </div>
            </div>
            <div className="column column-50">
              <div>
                <span className="label">Référence</span> :{" "}
                {item.order.reference}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="column">
              <span className="label">
                {+item.order.store_return ? "RETOUR MAGASIN" : "Client"}
              </span>{" "}
              : {item.order.client.identity.lastname}
            </div>
          </div>

          <Observations order={item.order} />

          <div className="delivery-options">
            {hasDropOff && <i className="fa fa-truck" title="Dépose" />}
            {hasDirectDropOff && (
              <i
                className="fas solid fa-door-closed"
                title="Dépose pied du camion"
              ></i>
            )}
            {hasInstallArticle && (
              <i
                className="fas fa-screwdriver"
                title="Installation / Montage"
              ></i>
            )}
            {haveHeavyPackage && (
              <i
                className="fas fa-weight-hanging"
                title="Colis lourd"
                style={{ color: "red" }}
              ></i>
            )}
          </div>
          {isDelayed && !isOnline ? (
            <h4>
              Cette commande sera synchronisée avec le serveur une fois la
              connexion à internet rétablie
            </h4>
          ) : null}
        </div>
        {displayButtonArticlesReturnedToStore && (
          <button
            className="button"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setModalReturnModalIsOpen({ item });
            }}
          >
            Indiquer les articles directement retournés en magasin
          </button>
        )}
      </Link>
      <button
        className={pickingButtonClasses}
        type="button"
        to={`/picking/${correspondingPickingId}`}
        onClick={() => {
          const {
            id: orderId,
            order: {
              delivery: { date: orderDate },
            },
          } = item;
          history.push(`/picking/${correspondingPickingId}`, {
            orderId,
            orderDate,
            orderHalfDay: halfDay,
          });
        }}
      >
        <span>Accéder au picking</span>
        <i className="fa fa-dolly fa-lg" aria-hidden="true" />
      </button>
    </div>
  );
};

DeliveryItem.propTypes = {
  isOrderReturned: PropTypes.bool,
  item: PropTypes.shape({
    position: PropTypes.string.isRequired,
    customerIsAbsent: PropTypes.bool,
    delivered: PropTypes.string,
    isDelayed: PropTypes.bool.isRequired,
    isSmsSent: PropTypes.bool.isRequired,
    someArticleHaveNotBeenDelivered: PropTypes.bool,
    id: PropTypes.string.isRequired,
    order: PropTypes.shape({
      last_event: PropTypes.string,
      articles: PropTypes.arrayOf(
        PropTypes.shape({
          article: PropTypes.shape({
            install_article: PropTypes.bool,
          }).isRequired,
        }).isRequired
      ).isRequired,
      contractor: PropTypes.shape({
        identity: PropTypes.shape({ code: PropTypes.string.isRequired })
          .isRequired,
      }).isRequired,
      reference: PropTypes.string.isRequired,
      store_return: PropTypes.bool.isRequired,
      client: PropTypes.shape({
        identity: PropTypes.shape({ lastname: PropTypes.string.isRequired })
          .isRequired,
      }).isRequired,
      delivery: PropTypes.shape({
        date: PropTypes.string.isRequired,
      }).isRequired,
      picking_status: PropTypes.string,
    }).isRequired,
  }).isRequired,
  isReordering: PropTypes.bool.isRequired,
  isLastItem: PropTypes.bool.isRequired,
  updatePosition: PropTypes.func.isRequired,
  setModalReturnModalIsOpen: PropTypes.func.isRequired,
  halfDay: PropTypes.string.isRequired,
  correspondingPickingId: PropTypes.string.isRequired,
};

export default DeliveryItem;
